<template>
  <div class="flex" style="flex-direction: var(--deriction-login)">
    <div
      class="surface-section w-full md:w-8 lg:w-5 p-4 md:p-8 flex flex-column justify-content-center h-auto md:h-screen"
    >
      <div class="mb-5">
        <div class="logo__xl mb-3 dark"></div>
        <div class="text-900 text-3xl font-medium mb-3">{{ loginTitle }}</div>
      </div>
      <div class="">
        <form @submit.prevent="login">
          <label for="phone2" class="block text-900 font-medium mb-2"
            >Телефон</label
          >
          <InputMask
            v-model="phone"
            id="phone2"
            type="tel"
            class="w-full mb-3 p-2"
            mask="+7 (999) 999-9999"
            @paste="onPaste"
          />

          <label for="password2" class="block text-900 font-medium mb-2"
            >Пароль</label
          >
          <Password
            v-model="password"
            inputClass="w-full p-2"
            class="w-full mb-3"
            id="password2"
            feedback="false"
            toggleMask
          ></Password>

          <Button
            type="submit"
            label="Вход"
            icon="pi"
            class="w-full p-2"
            :loading="isLoading"
          >
          </Button>
        </form>
        <div v-if="getProject !== 'specoil' && getProject !== 'fullfuel'">
          <div
            v-if="this.config.APP_LINK[getProject].length"
            class="md:block w-full mt-6 sm:w-12 text-center sm:mb-5"
          >
            <p class="mb-3 text-xl">Мобильное приложение:</p>

            <div class="flex justify-content-center">
              <div
                v-for="(item, i) in this.config.APP_LINK[getProject]"
                :key="item.name + i"
              >
                <LoginAppLink
                  class="mr-2"
                  :download="item['download']"
                  :dsc="item['dsc']"
                  :name-store="item['name-store']"
                  :imgWidth="item['imgWidth']"
                  :img-path="item['img']"
                  :link="item['link']"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="getProject === 'fullfuel'">
          <!-- <div> -->
          <div class="mt-4">
            <div class="">Круглосуточная горячая линия</div>
            <div class="text-xl mt-2 mb-4 text-primary">
              <h2><a href="tel:+88005500914">8-800-550-09-14</a></h2>
            </div>
            <div class="">
              г. Москва, Киевское ш. 22-й км., домовл. 4, стр. 2, этаж 4, блок
              В, подъезд 16, офис 429В
            </div>
          </div>
          <div class="flex justify-content-between mt-6 gap-4 flex-wrap">
            <a
              href="https://ftcard.ru/upload/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82.pdf"
              >Регламент</a
            >
            <a href="https://ftcard.ru/politika-konfidentsialnosti/"
              >Политика конфиденциальности</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block w-8 bg-no-repeat bg-cover bg-login"></div>
  </div>
</template> 
<script>
import api from "@/api";
import LoginAppLink from "@/components/Login/LoginAppLink.vue";
import config from "@/configs";
import store from "@/store";

export default {
  components: {
    LoginAppLink,
  },
  data() {
    return {
      config: config,
      phone: "",
      password: "",
      isLoading: false,
      loginTitle: window.appLoginTitle,
      limks: this.config,
    };
  },
  mounted() {
    window.document.body.style.paddingLeft = "0 !important";
    let href = document.location.host;
    if (document.location.hash === "#dev") {
      console.log(document.location.hash);
      localStorage.setItem("isDev", false);
    }
    let isDev = localStorage.getItem("isDev");
    if ((href === "localhost:8080" || href === "tanos.dt-teh.ru") && !isDev) {
      this.$toast.add({
        severity: "info",
        summary: `Это тестовый ресурс для разработчиков.`,
        detail: `Стабильная версия на официальном сайте.`,
      });
    }
    store.commit("setCurCompany", null);
    // localStorage.removeItem("vuex");
    // localStorage.removeItem('token')
  },
  created() {},
  computed: {
    getProject() {
      let href = document.location.host;
      switch (href) {
        case "tanos.dt-teh.ru":
          return "dt";
        case "lk.dt-teh.ru":
          return "dt";
        case "bc.expcard.ru":
          return "exp";
        case "lk.specoilserv.ru":
          return "specoil";
        case "bc.ftcard.ru":
          return "fullfuel";
        default:
          return "dt";
      }
    },
  },
  methods: {
    login: function () {
      this.isLoading = true;
      if (this.phone.length && this.password.length) {
        api
          .post("/token/get", {
            phone: this.phone.match(/\d+/g).join(""),
            password: this.password,
          })
          .then((r) => {
            localStorage.setItem(`__token`, r.data.access);
            setTimeout(() => {
              api.defaults.headers.Authorization = "Bearer " + r.data.access;
              if (history.state.back !== null) {
                this.$router.back();
              } else {
                this.$router.push("/");
                this.isLoading = false;
              }
            }, 250);
          })
          .catch((err) => {
            console.log(err);
            if (err.request.status == "401") {
              this.$toast.add({
                severity: "error",
                summary: "Доступ запрещен",
                detail: "Проверьте логин и пароль!",
                life: 5000,
              });
              this.isLoading = false;
            } else if (err.request.status === 0) {
              this.$toast.add({
                severity: "warn",
                summary: `Нет связи с сервером ${err.request.status}`,
                detail: "Попробуйте позднее!",
                life: 5000,
              });
              this.isLoading = false;
            } else {
              this.$toast.add({
                severity: "error",
                summary: `Неизвестная ошибка ${err.request.status}`,
                detail: "Попробуйте позднее!",
                life: 5000,
              });
              this.isLoading = false;
            }
            console.log(err);
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Нет доступа",
          detail: "Введите логин и пароль!",
          life: 2000,
        });
        this.isLoading = false;
      }
    },
    onPaste: function (e) {
      let tel = e.clipboardData.getData("text/plain").match(/\d+/g).join("");
      let newTel = tel.split("").slice(tel.split("").indexOf("9"));

      e.target.value = "";
      e.target.value = newTel;
      return false;
    },
  },
};
</script>
<style>
.bg-login {
  background-image: var(--login-bg-url);
}

.p-password-panel {
  display: none !important;
}
.p-button .p-button-icon-left {
  margin-right: 0rem !important;
}
.pi.pi-eye,
.pi.pi-eye-slash {
  cursor: pointer;
}
.p-button-label {
  flex: 1 1 auto;
}
/* body {
  padding-left: 0 !important;
} */
</style>