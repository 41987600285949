<template>
  <a
    v-if="download" 
    download
    :href="link" 
    style="width: 90px"
    class="
      block
      border-round
      flex flex-column
      justify-content-between
      align-items-center
      shadow-3
      text-white
      bg-bluegray-800
      hover:bg-bluegray-900 hover:shadow-4
      py-2
      px-1
    "
  >
    <div
      style="height: 30px"
      class="flex justify-content-center align-items-center"
    >
      <img :src="imgPath" :width="imgWidth" />
    </div>
    <div class="mt-2">
      <div class="" style="font-size: 12px">
        {{ dsc }}
      </div>
      <div style="font-size: 14px">
        {{ nameStore }}
      </div>
    </div>
  </a>
  <a
    v-else
    target="_blank"
    style="width: 90px"
    :href="link" 
    :class="link === undefined ? 'opacity-30' : ''"
    :style="link === undefined ? 'cursor: no-drop' : ''"
    class="
      block
      border-round
      flex flex-column
      justify-content-between
      align-items-center
      shadow-3
      text-white
      bg-bluegray-800
      hover:bg-bluegray-900 hover:shadow-4
      py-2
      px-1
    "
  >
    <div
      style="height: 30px"
      class="flex justify-content-center align-items-center"
    >
      <img :src="imgPath" :width="imgWidth" />
    </div>
    <div class="mt-2">
      <div class="" style="font-size: 12px">
        {{ dsc }}
      </div>
      <div style="font-size: 14px">
        {{ nameStore }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "LoginAppLink",
  props: {
    link: {
      type: String,
      requared: true,
    },
    dsc: {
      type: String,
      requared: true,
      default: "Скачать в",
    },
    nameStore: {
      type: String,
      requared: true,
      default: "",
    },
    imgPath: {
      type: String,
      requared: true,
      default: "/",
    },
    imgWidth: {
      type: Number,
      requared: false,
      default: 30,
    },
    download: {
      type: Boolean,
      requared: true,
      default: false,
    },
  },
};
</script>

<style>
</style>